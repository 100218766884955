import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as c from "components";
import { useSelectedLanguage } from "language";
import { usePrintStore } from "stores";
import CircularLetter from "./circularGrade";
import calculatLetterGrade from "./utils";

interface Props {
  progress: number;
  itemSize: number;
}

const LetterGradeAvatar = (props: any) => {
  const {
    esgGrade,
    environmental,
    social,
    governance,
    company,
    gradeConfig,
    activity,
  } = props;
  const { printing, setPrinting } = usePrintStore();
  const styles = {
    color: "#1d8ea8",
    backgroundColor: "white",
    padding: "1.5rem",
  };

  const avatarContainer = {
    position: "relative" as "relative",
    display: "inline-block",
  };
  const progress = {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  };
  const { selectedDictionary } = useSelectedLanguage();
  return (
    <>
      <Grid container spacing={2} alignItems="stretch" className="padding">
        <Grid item md={6} xs={printing ? 4 : 12}>
          <c.Card.Basic
            content={activity}
            name={selectedDictionary.reportIndexCell1}
          />
        </Grid>
        <Grid item md={6} xs={printing ? 4 : 12}>
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <Typography variant="h4" gutterBottom>
                {selectedDictionary.esgGradeCell1}
              </Typography>
              <CircularLetter
                {...{
                  letterGrade: calculatLetterGrade(
                    esgGrade?.grade,
                    gradeConfig,
                    company
                  ),
                  progress: esgGrade?.grade,
                  itemSize: printing ? 90 : 120,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        {/* 
        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                תחום ממשל תאגידי
              </Typography>
              <Avatar style={styles}>
                {calculatLetterGrade(governance?.grade, gradeConfig, company)}
              </Avatar>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                תחום חברה
              </Typography>
              <Avatar style={styles}>
                {calculatLetterGrade(social?.grade, gradeConfig, company)}
              </Avatar>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                תחום סביבה
              </Typography>
              <Avatar style={styles}>
                {calculatLetterGrade(
                  environmental?.grade,
                  gradeConfig,
                  company
                )}
              </Avatar>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
};

export default LetterGradeAvatar;
