import { AuthContext, CompaniesContext, FiltersContext } from "context";
import { useSelectedLanguage } from "language";
import { useContext, useEffect, useState } from "react";
import { createStore, useStore } from "react-hookstore";
import {
  BadActivityTypes,
  DrawerInputTypes,
  FilterItem,
  FiltersPayload,
  SDGActivityTypes,
} from "types";
import { debounce } from "utils";
import {
  IncidentsFilter,
  MiscFilterTypes,
  RelativeToGrade,
  TextValuePair,
  incidentsFlagsFilter,
} from "./types";

const _ = require("lodash");

export const COMPANY_FILTERS = "COMPANY_FILTERS";
export const QUERY_FILTERS = "QUERY_FILTERS";
export const SEARCH_TEXT = "SEARCH_TEXT";
export const SEARCH_TEXT_FUNDS = "SEARCH_TEXT_FUNDS";
export const PRINT_STORE = "PRINT_STORE";
export const LOADING_STORE = "LOADING_STORE";
export const QUERY_FILTERS_FUNDS = "QUERY_FILTERS_FUNDS";

const letterGrades = [
  { value: "AAA", text: "AAA" },
  { value: "AA", text: "AA" },
  { value: "A", text: "A" },
  { value: "BBB", text: "BBB" },
  { value: "BB", text: "BB" },
  { value: "B", text: "B" },
  { value: "C", text: "C" },
];
createStore<any>(QUERY_FILTERS, {});
createStore<any>(SEARCH_TEXT, "");
createStore<any>(SEARCH_TEXT_FUNDS, "");
createStore<any>(QUERY_FILTERS_FUNDS, {});
createStore<any>(COMPANY_FILTERS, []);
createStore<any>(LOADING_STORE, false);

export const useCompaniesFilters = () => {
  const { selectedLanguage, selectedDictionary, selectedTypeTitles } =
    useSelectedLanguage();
  const [filters, setFilters] = useStore<FilterItem[]>(COMPANY_FILTERS);
  const [searchTerm, setSearchTerm] = useStore<string>(SEARCH_TEXT);
  const [filtersLoading, setFiltersLoading] = useStore<boolean>(LOADING_STORE);
  const [queryFilters, setQueryFilters] =
    useStore<FiltersPayload>(QUERY_FILTERS);
  const { indices, sectors, incidents, loadingFilters } =
    useContext(FiltersContext);
  const { userData } = useContext(AuthContext);

  const { setPage } = useContext(CompaniesContext);
  const [timeoutId, setTimeoutId] = useState<number>(0);

  const clearFilters = () => {
    setPage(0);
    const clearedFilters = filters?.map((f: FilterItem) => ({
      ...f,
      values:
        f.type === DrawerInputTypes.Slider
          ? [
              { text: "0", value: 0 },
              { text: "100", value: 100 },
            ]
          : [],
    }));
    setFilters(clearedFilters);
  };

  const handleIncidentsFlagFilter = (
    filter: FilterItem<any>,
    newFilters: FiltersPayload
  ) => {
    const selectedFiltersKeys = filter.values?.map(
      (selectedFilter) => selectedFilter.value
    );

    if (_.isEmpty(selectedFiltersKeys)) {
      newFilters.incidents = undefined;
      newFilters.eventsFlags = undefined;
      return;
    }

    const flagTypeByFilterKey: incidentsFlagsFilter[] = [
      { key: IncidentsFilter.INCIDENTS_FLAG_HIGH, flagType: "High" },
      { key: IncidentsFilter.INCIDENTS_FLAG_CRITICAL, flagType: "Critical" },
      { key: IncidentsFilter.INCIDENTS_FLAG_NONE, flagType: "None" },
      { key: IncidentsFilter.NO_INCIDENTS_FLAG_NONE, flagType: "" },
    ];

    const flagsArray: string[] = [];

    flagTypeByFilterKey.map((flagType) => {
      if (
        selectedFiltersKeys?.includes(flagType.key) &&
        !flagsArray.includes(flagType.flagType)
      ) {
        flagsArray.push(flagType.flagType);
      }
    });

    newFilters.eventsFlags = flagsArray;

    newFilters.incidents = true;
    if (selectedFiltersKeys?.includes(IncidentsFilter.NO_INCIDENTS_FLAG_NONE)) {
      selectedFiltersKeys.length > 1
        ? (newFilters.incidents = undefined)
        : (newFilters.incidents = false);
    }
  };

  useEffect(() => {
    if (!loadingFilters) {
      const fs = getFilters(selectedDictionary)?.map((f: FilterItem) => {
        f.values = [];
        switch (f.id) {
          case "indexes":
            f.options = indices;
            break;
          case "sectors":
            f.options = sectors.sort((a, b) =>
              a.text > b.text ? 1 : b.text > a.text ? -1 : 0
            );
            break;
          case "sdgActivities":
            f.options =
              Object.keys(SDGActivityTypes).map((i: any) => ({
                text: selectedTypeTitles[i] || "",
                value: i,
              })) || [];
            f.options.unshift({
              text: selectedDictionary.no,
              value: MiscFilterTypes.NONE,
            });
            f.options.unshift({
              text: selectedDictionary.yes,
              value: MiscFilterTypes.SOME,
            });
            break;
          case "badActivities":
            f.options =
              Object.keys(BadActivityTypes).map((i: any) => ({
                text: selectedTypeTitles[i] || "",
                value: i,
              })) || [];
            f.options.unshift({
              text: selectedDictionary.no,
              value: MiscFilterTypes.NONE,
            });
            f.options.unshift({
              text: selectedDictionary.yes,
              value: MiscFilterTypes.SOME,
            });
            break;
          case "incidents":
            f.options = incidents;
            break;
          case "relativeEsgGrade":
          case "relativeEnvGrade":
          case "relativeGovGrade":
          case "relativeSocialGrade":
            f.options = [
              {
                text: selectedDictionary.equalOrAboveAverage,
                value: RelativeToGrade.ABOVE,
              },
              {
                text: selectedDictionary.belowAverage,
                value: RelativeToGrade.BELOW,
              },
            ];
            break;
          case "esgGrade":
          case "relativeEsgGradePercentile":
          case "envGrade":
          case "govGrade":
          case "socialGrade":
          case "percentileInSector":
            f.options = [
              { text: "0", value: 0 },
              { text: "100", value: 100 },
            ];
            f.values = [
              { text: "0", value: 0 },
              { text: "100", value: 100 },
            ];
            break;
          case "letterGrades":
            f.options = letterGrades;
            break;
          default:
            break;
        }
        return f;
      });
      setFilters(fs);
      setFiltersLoading(false);
    } else {
      setFiltersLoading(true);
    }
  }, [indices, sectors, incidents]);

  // @ts-ignore
  useEffect(() => {
    const newFilters = { ...queryFilters };
    for (const filter of filters) {
      switch (filter.id) {
        case "indexes":
          newFilters.indexes = filter.values?.map(
            (i: TextValuePair<string>) => i.value
          );
          break;
        case "sectors":
          newFilters.primarySector = filter.values?.map(
            (i: TextValuePair<string>) => i.value
          );
          break;
        case "incidents":
          handleIncidentsFlagFilter(filter, newFilters);
          break;
        case "sdgActivities":
          delete newFilters.isSdgActivities;
          const yesSdg =
            filter.values?.findIndex(
              (i: TextValuePair<SDGActivityTypes | MiscFilterTypes>) =>
                i.value === MiscFilterTypes.SOME
            ) !== -1;
          const noSdg =
            filter.values?.findIndex(
              (i: TextValuePair<SDGActivityTypes | MiscFilterTypes>) =>
                i.value === MiscFilterTypes.NONE
            ) !== -1;
          if (yesSdg && !noSdg) {
            newFilters.isSdgActivities = true;
          } else if (noSdg && !yesSdg) {
            newFilters.isSdgActivities = false;
          }
          const sdgTypes = filter.values?.filter(
            (i: TextValuePair<SDGActivityTypes | MiscFilterTypes>) =>
              i.value !== MiscFilterTypes.NONE &&
              i.value !== MiscFilterTypes.SOME
          );
          newFilters.sdgActivities = sdgTypes?.map(
            (i: TextValuePair<SDGActivityTypes>) => i.value
          );
          break;
        case "badActivities":
          delete newFilters.isBadActivities;
          const yesBad =
            filter.values?.findIndex(
              (i: TextValuePair<BadActivityTypes | MiscFilterTypes>) =>
                i.value === MiscFilterTypes.SOME
            ) !== -1;
          const noBad =
            filter.values?.findIndex(
              (i: TextValuePair<BadActivityTypes | MiscFilterTypes>) =>
                i.value === MiscFilterTypes.NONE
            ) !== -1;
          if (yesBad && !noBad) {
            newFilters.isBadActivities = true;
          } else if (noBad && !yesBad) {
            newFilters.isBadActivities = false;
          }
          const badTypes = filter.values?.filter(
            (i: TextValuePair<BadActivityTypes | MiscFilterTypes>) =>
              i.value !== MiscFilterTypes.NONE &&
              i.value !== MiscFilterTypes.SOME
          );
          newFilters.badActivities = badTypes?.map(
            (i: TextValuePair<BadActivityTypes>) => i.value
          );
          break;
        case "relativeEsgGrade":
        case "relativeEnvGrade":
        case "relativeGovGrade":
        case "relativeSocialGrade":
          setRelativeGrade(filter, newFilters);
          break;
        case "esgGrade":
        case "envGrade":
        case "govGrade":
        case "socialGrade":
        case "percentileInSector":
        case "relativeEsgGradePercentile":
          setMinMaxGrade(filter, newFilters);
          break;
        case "letterGrades":
          newFilters.letterGrades = filter.values?.map(
            (i: TextValuePair<string>) => i.value
          );
          break;
        default:
          break;
      }
    }
    newFilters.searchText = searchTerm;
    setTimeoutId(debounce(() => setQueryFilters(newFilters), timeoutId));
  }, [filters, searchTerm]);

  return {
    filters,
    setFilters,
    clearFilters,
    searchTerm,
    setSearchTerm,
    queryFilters,
    setQueryFilters,
    loadingFilters,
  };
};

const setRelativeGrade = (filter: FilterItem, newFilters: FiltersPayload) => {
  const { id, values } = filter;
  const value =
    values?.length === 1 || values?.length === 2 ? values[0].value : undefined;
  switch (id) {
    case "relativeEsgGrade":
      newFilters.relativeGrade = value;
      break;
    case "relativeEnvGrade":
      newFilters.relativeEnvGrade = value;
      break;
    case "relativeGovGrade":
      newFilters.relativeGovGrade = value;
      break;
    case "relativeSocialGrade":
      newFilters.relativeSocialGrade = value;
      break;
    default:
      break;
  }
};

const setMinMaxGrade = (filter: FilterItem, newFilters: FiltersPayload) => {
  const { id, values } = filter;
  if (values?.length === 2) {
    let minValue = values[0].value ?? undefined;
    let maxValue = values[1].value !== 100 ? values[1].value : undefined;
    switch (id) {
      case "esgGrade":
        newFilters.minGrade = minValue;
        newFilters.maxGrade = maxValue;
        break;
      case "envGrade":
        newFilters.minEnvGrade = minValue;
        newFilters.maxEnvGrade = maxValue;
        break;
      case "govGrade":
        newFilters.minGovGrade = minValue;
        newFilters.maxGovGrade = maxValue;
        break;
      case "socialGrade":
        newFilters.minSocialGrade = minValue;
        newFilters.maxSocialGrade = maxValue;
        break;
      case "percentileInSector":
        newFilters.minPercentileGrade = minValue;
        newFilters.maxPercentileGrade = maxValue;
        break;
      case "relativeEsgGradePercentile":
        newFilters.minEsgPercentileGrade = minValue;
        newFilters.maxEsgPercentileGrade = maxValue;
        break;
      default:
        break;
    }
  }
};

export const useFundsFilters = () => {
  const [searchTermFunds, setSearchTermFunds] =
    useStore<string>(SEARCH_TEXT_FUNDS);
  const [queryFilters, setQueryFilters] =
    useStore<FiltersPayload>(QUERY_FILTERS_FUNDS);
  useEffect(() => {
    const newFilters = { ...queryFilters };
    newFilters.searchText = searchTermFunds;
    setQueryFilters(newFilters);
  }, [searchTermFunds]);
  return {
    searchTermFunds,
    setSearchTermFunds,
    queryFilters,
    setQueryFilters,
  };
};

createStore<any>(PRINT_STORE, false);

export const usePrintStore = () => {
  const [printing, setPrinting] = useStore<any>(PRINT_STORE);

  return { printing, setPrinting };
};

const getFilters = (dictionary: any) => {
  const filtersList: FilterItem[] = [
    {
      title: dictionary.filters.indecies,
      id: "indexes",
      type: DrawerInputTypes.Checkbox,
    },
    {
      title: dictionary.filters.sectors,
      id: "sectors",
      type: DrawerInputTypes.Checkbox,
    },
    {
      title: dictionary.filters.letter,
      id: "letterGrades",
      type: DrawerInputTypes.Checkbox,
    },
    {
      title: dictionary.esgGrade,
      id: "esgGrade",
      type: DrawerInputTypes.Slider,
    },
    {
      title: dictionary.esgComparedToSector,
      id: "relativeEsgGrade",
      type: DrawerInputTypes.Radio,
    },
    {
      title: dictionary.esgGradePercentile,
      id: "relativeEsgGradePercentile",
      type: DrawerInputTypes.Slider,
    },
    {
      title: dictionary.percentileInSector,
      id: "percentileInSector",
      type: DrawerInputTypes.Slider,
    },
    {
      title: dictionary.incidentsAndAlerts,
      id: "incidents",
      type: DrawerInputTypes.Checkbox,
    },
    {
      title: dictionary.sustainableDevelopment,
      id: "sdgActivities",
      type: DrawerInputTypes.Checkbox,
    },
    {
      title: dictionary.disputableGoods,
      id: "badActivities",
      type: DrawerInputTypes.Checkbox,
    },
  ];

  return filtersList;
};
