import { ApolloError, useLazyQuery } from "@apollo/client";
import { AuthContext } from "context";
import { formatCompanyData, formatCompanyReport } from "formatters";
import { COMPANY_DATA, COMPANY_REPORT, CONFIG } from "graphql/query";
import { useSelectedLanguage } from "language";
import React, { createContext, useContext, useState } from "react";
import { CompanyData, CompanyReport } from "types";

interface CompanyContextState {
  setCompanyID: any;
  setIssuerId: any;
  loadingCompany: boolean;
  loadingConfig: boolean;
  loadingCompanyData: boolean;
  issuerId: string;
  error: ApolloError | undefined;
  company: CompanyReport;
  companyData: CompanyData;
  config: { featureIssue: string; disclaimer: string };
  getCompany: () => {};
  getConfig: () => {};
  getCompanyData: () => {};
}

export const CompanyContext = createContext({} as CompanyContextState);

export const CompanyProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { userData } = useContext(AuthContext);
  const [companyID, setCompanyID] = useState<number | string>();
  const [issuerId, setIssuerId] = useState<number | string>();
  const { selectedLanguage, selectedDictionary } = useSelectedLanguage();
  const companyFilters: any[] = [
    { users: { id: { eq: userData?.id } } },
    { primarySector: { users: { id: { eq: userData?.id } } } },
  ];
  if (userData?.orgId) {
    companyFilters.push({
      indexes: { organizations: { id: { eq: userData?.orgId } } },
    });
  } else {
    companyFilters.push({ indexes: { users: { id: { eq: userData?.id } } } });
  }

  const [
    getCompany,
    { data: companyReport, loading: loadingCompany, error: companyReportError },
  ] = useLazyQuery(COMPANY_REPORT, {
    variables: {
      id: companyID,
      filters: {
        or: companyFilters,
      },
    },
  });
  const [
    getConfig,
    { data: config, loading: loadingConfig, error: configError },
  ] = useLazyQuery(CONFIG);
  const [
    getCompanyData,
    { data: companyData, loading: loadingCompanyData, error: companyDataError },
  ] = useLazyQuery(COMPANY_DATA, {
    variables: {
      filters: {
        issuerId: issuerId,
      },
    },
  });

  const iState = {
    setCompanyID,
    setIssuerId,
    error: companyReportError && configError,
    loadingCompany,
    loadingConfig,
    loadingCompanyData,
    company: formatCompanyReport(
      companyReport?.company?.data?.attributes,
      companyReport?.company?.data?.id,
      selectedDictionary,
      selectedLanguage
    ),
    companyData: formatCompanyData(
      companyData?.companyDataQuery?.data?.attributes
    ),
    getCompany,
    getCompanyData,
    issuerId: issuerId,
    config: {
      featureIssue:
        config?.config?.data?.attributes?.featureIssue?.data?.attributes?.name,
      disclaimer: config?.config?.data?.attributes?.disclaimer,
    },
    getConfig,
  } as CompanyContextState;

  return (
    <CompanyContext.Provider value={iState}>{children}</CompanyContext.Provider>
  );
};
